:root {
  --primary: #161b21;
  --secondary: #f4a950;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.font_righteous {
  font-family: "Righteous", cursive;
}

/* HEADER */

header {
  padding: 10px 0px;
  transition: all 300ms ease-in;
}

header .header_logo {
  flex-grow: 1;
}
header .header_logo_name {
  font-size: clamp(1rem, 10vw, 3rem);
  color: white;
  text-transform: uppercase;
}

.navlinks {
  margin-right: 2rem;
}

.navlinks span {
  margin-left: 2rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.navlinks span:hover {
  color: white;
}

.drawerlinks span {
  display: block;
  margin: 10px 40px;
  padding: 3px;
  text-decoration: none;
  color: black;
}
.drawerlinks a:hover {
  color: burlywood;
}

/* BANNER */
.carrousel_image {
  /* filter: blur(100px); */

    background: linear-gradient(rgba(60, 84, 92, 0.7), rgba(39, 80, 63, 0.75)),
      url("images/coding-bg.jpg") !important;
      /* background-blend-mode: multiply; */
  background-size: cover !important;
  position: relative;
  height: auto;
  min-height: 100vh;
  overflow: hidden;

  /* min-height: 600px; */
}
.banner_container {
  /* backdrop-filter: blur(0px); */
  height: auto;
  /* min-height: 600px; */
  width: 100%;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: column;
  /* margin-top: 20px !important; */
}
#tsparticles {
  height: auto;
  min-height: 100vh;
}

.banner_container p.my-data  {
  font-size : clamp(2rem, 10vw, 4rem);
  color: var(--secondary);
  line-height: clamp(2rem, 10vw, 4rem);
  font-weight: bolder;
}

.banner_container p{
  font-size: clamp(2rem, 8vw, 4rem);
  color: white;
  line-height: clamp(2rem, 10vw, 4rem);
  height: auto !important;
  font-weight: bold;
}

.banner_container span {
  line-height: 1px;
  color: var(--secondary);
}

.mouse_link {
  all: unset;
  width: 3px;
  padding: 20px 15px;
  height: 35px;
  border: 3px solid #fff;
  border-radius: 25px;
  opacity: 0.7;
  margin-top: 10px;
  cursor: pointer;
  animation: mouseAnimation 0.6s alternate infinite;
}

@keyframes mouseAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
}

.mouse_link:hover {
  opacity: 1;
}

.mouse_scroll {
  display: block;
  width: 3.5px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation: mouseScroll 3s infinite;
}

@keyframes mouseScroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

/* ABOUT */
.about {
  margin: 0 auto;
  box-sizing: border-box !important;
  margin-top: 0px;
}
.about .inner_container {
  margin-top: 30px;
  display: flex;
}


.inner_container{
  /* margin-top: clamp(2rem, 10vmin, 5rem); */
  /* height: 80vh !important; */
}



.about_title h2 {
  text-align: center; 
  color: white;
  margin-top: 60px;

}
.about_title {
  padding-top: 1px;
}


.full_height{
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 20px;
  background-color: #2B2B2B;
  /* background: linear-gradient(-45deg, #897069, #9f4c6c, #7cd3f3, #0c0f0e); */
}

.profile {
  margin-top: 10px !important;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  text-align: center;
  background-color: rgba(52, 51, 51, 0.9);
  padding: 30px 25px;
  border-radius: 5px;
  min-height: 500px;
}

.profile p {
  /* color: rgb(211, 211, 211);
  line-height: 100%;
  font-size: 18px; */
}

.profile h3 {
  color: var(--secondary);
}

.large_profile {
  margin-right: 60px;
}

.skills_list {
  margin: 0 auto;
  /* background-color: gainsboro; */
  padding: 30px 25px;
  border-radius: 5px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  line-height: 13px;
}

.skill_container {
  border-radius: 5px;
  width: 100%;
  height: 30px;
  background-color: #CCCCCC;
  margin-top: 15px;
  display: flex;
  overflow: hidden;
  position: relative;
}
 .skills_list h2 {
  margin: 0px !important;
 }

.skill_name {
  background-color: var(--secondary);
  color: var(--primary);
  font-size: 14px;
  text-align: center;
  /* padding-top: 2px; */
  width: 120px;
  position: absolute;
  left: 0;
  z-index: 2;
  height: 30px;
  line-height: 30px;

}

.skill_percent {
  /* width: calc(90% - 120px); */
  color: var(--secondary);
  height: 30px;
  z-index: 1;
  box-sizing: border-box;
  width: attr(percent);
  background-color: #333;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: end;
  padding-top: 1px;
  padding-right: 10px;
  line-height: 30px;
  position: absolute;
  left: 120px;
  font-size: 12px;
  /* transform: translateX(-400px); */
  /* animation: example 1.5s ease 0s 1 forwards; */
}

@keyframes example {
  from {  transform: translateX(-400px);
  }
  to {  transform: translateX(0px);
  }
}

#home {
  padding: 0;
}

#about {
  padding: 100px 30px;
}

.about-container {
  width: 98%;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  gap:40px;
  align-items: flex-start;
  height: auto;
}
.profile-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
}

.profile-container h2{
  color: var(--secondary) !important;

}

.profile-container img {
  box-shadow: 0px 4px 4px 2px var(--secondary);
}



.profession-container.wrapper {
	/* max-width: 900px; */
	margin: 0 auto;
  width: 100%;
}

.profession-container ul {
  display: flex;
  flex-direction: row;
	list-style: none;
  justify-content: center;
  align-items: center;
	padding: 0;
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 800px) {
  .profession-container ul {
    flex-direction: column;
  }
  .about-container {
    flex-direction: column;
  }
  .contact-me-container {
    display: flex;
    justify-content: center !important;
  }
}

.profession-container li {
	display: flex;
	gap: 1rem;
	background: rgb(186, 221, 249);
	/* padding: 25px; */
  line-height: 50px;
  height: 50px;
  padding: 15px;
  box-sizing: content-box;
	border-radius: 10px;
  width: 80%;

  max-width: 600px;
	box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.1);
  margin-left: 10px;
}
.profession-container li img{
  columns: var(--secondary);
  width: 50px;
}


.profession-container li:nth-child(even) {
	flex-direction: row-reverse;
	background: rgb(234, 237, 176);
	/* margin-right: -2rem;
	margin-left: 2rem; */
}

.profession-container li:nth-child(even)::before {
	transform: rotateY(180deg);
}

.contact-me-container {
  display: flex;
  justify-content: end;
}
.contact-button {
  /* float: right; */
}

/* Contact */
#contact {
  height: auto;
  min-height: 100vh;
}

/* Loader */
.lds-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1111;
  background-color: rgba(114, 109, 109, 0.9);
}
.lds-roller {
  display: inline;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--secondary);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}